<template>
  <div class="course-page">
    <a-spin :spinning="loading">
      <div v-if="!courseData?.course_id && !loading" class="text-center">
        <a-empty description="暂无课程信息" class="mb-30"></a-empty>
        <a-button type="primary" @click="goToIndex">返回首页</a-button>
      </div>
      <div v-else class="course-box">
        <div class="course-top">
          <div class="course-top-1">
            <img
              v-if="courseData?.course_image"
              :src="courseData?.course_image"
              alt=""
              class="course-poster"
            />
            <div class="page-main course-top-info">
              <a-breadcrumb :separator="'>'">
                <a-breadcrumb-item style="color: #fff">工种</a-breadcrumb-item>
                <a-breadcrumb-item style="color: #fff">等级</a-breadcrumb-item>
              </a-breadcrumb>
              <div class="course-title">{{ courseData?.course_name }}</div>
              <div class="course-top-row">
                <span v-if="courseData?.course_ishot == 1">
                  <fire-two-tone two-tone-color="#e72929" /> 热门 ·
                </span>
                <span>共{{ courseData?.course_section?.length }}节</span>
                ·
                <span>{{ courseData?.course_view_num }}次播放</span>
              </div>
            </div>
          </div>
          <div class="course-tabx-box">
            <div class="page-main flex course-top-row">
              <div
                :class="{
                  'course-tab-title': true,
                  'course-tab-active': activeId == 1,
                }"
                @click="changeTab(1)"
              >
                课程目录
              </div>
              <div
                :class="{
                  'course-tab-title': true,
                  'course-tab-active': activeId == 2,
                }"
                @click="changeTab(2)"
              >
                课程介绍
              </div>
            </div>
          </div>
        </div>
        <div class="course-main page-main">
          <div v-if="activeId == 1" class="course-section-box">
            <div v-if="courseSection.length == 0" class="course-section-item">
              <a-empty description="暂无章节"></a-empty>
            </div>
            <template v-for="(item, index) in courseSection" :key="index">
              <div class="course-section-item">
                <div class="course-section-title">{{ item.section_name }}</div>
                <div class="course-resource-box">
                  <template
                    v-for="(item2, index2) in item.course_resource"
                    :key="index2"
                  >
                    <!-- <a-tooltip>
                      <template #title>扫描右侧微信小程序码观看</template> -->
                    
                     <!-- @click="goToResource(item2, item)" -->
                    <div
                      class="resource-title flex"
                     @click="goToResource(item2, item)"
                    >
                      <video-camera-outlined
                        style="font-size: 18px; margin-right: 8px"
                      />
                      <div class="resource-title-text">
                        {{ item2.resource_name }}
                      </div>
                      <div>({{ getTime(item2.resource_duration) }})</div>
                      <div>{{ item2.resource_view_num }}次播放</div>
                      <div
                        v-if="item2.course_resource_record"
                        class="resource-record"
                      >
                        课程已完成
                        {{ item2.course_resource_record.record_resource_per }}%
                        <!-- {{
                            getTime(
                              item2.course_resource_record
                                .record_resource_duration
                            )
                          }} -->
                      </div>
                    </div>
                    <!-- </a-tooltip>· -->
                  </template>
                  <a-empty
                    v-if="item.course_resource.length == 0"
                    description="暂无资源"
                  ></a-empty>
                </div>
              </div>
            </template>
          </div>
          <div v-else class="course-section-box">
            <div class="course-section-item">
              <a-empty
                v-if="!courseData?.course_content"
                description="暂无课程介绍"
              ></a-empty>
              <div
                class="course-content"
                v-else
                v-html="courseData?.course_content"
              ></div>
            </div>
          </div>
          <a-affix
            :offset-top="top"
            :style="{
              position: 'absolute',
              top: '-148px',
              right: 0,
              'z-index': 2,
            }"
          >
            <div class="course-side">
              <img src="~@/assets/wechat.jpg" alt="" />
              <div>请用微信扫码</div>
              <div>进入小程序学习</div>
            </div>
          </a-affix>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "@vue/runtime-core";
import { message } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { getCourseData } from "@/api/main.js";
import { FireTwoTone, VideoCameraOutlined } from "@ant-design/icons-vue";
// import Md5 from "js-md5";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";

export default defineComponent({
  name: "CoursePage",
  components: { FireTwoTone, VideoCameraOutlined },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pageData = reactive({
      courseData: null,
      activeId: 1,
      courseSection: [],
      top: 100,
      loading: true,
      courseId: null,
      studentId: null,
      record_study_period_day: null,
      record_study_resource_day: null,
    });
    const goToIndex = () => {
      router.replace("/index");
    };
    const getCourseDataFun = (_courseId, _studentId) => {
      // pageData.loading = true;
      getCourseData({ course_id: _courseId, student_id: _studentId })
        .then((res) => {
          if (res.code == 200) {
            pageData.courseData = res.data.course;
            pageData.courseSection = res.data.course.course_section;
            pageData.record_study_period_day = res.data.record_study_period_day;
            pageData.record_study_resource_day =
              res.data.record_study_resource_day;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };

    const changeTab = (_index) => {
      pageData.activeId = _index;
    };

    const getTime = (_time) => {
      let time = "";
      let hours = _time > 3600 ? Math.floor(_time / 3600) : 0;
      let minutes = _time > 60 ? Math.floor((_time % 3600) / 60) : 0;
      let second = (_time % 3600) % 60;
      time = `${hours.toString().padStart(2, 0)}:${minutes
        .toString()
        .padStart(2, 0)}:${second.toString().padStart(2, 0)}`;
      return time;
    };

    watch(
      () => route.query,
      (val) => {
        if (route.path == "/course/course") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.courseId = query[0];
            pageData.studentId = query[1];
            getCourseDataFun(query[0], query[1]);
          } else {
            pageData.loading = false;
            pageData.courseData = [];
            pageData.courseSection = [];
            message.error("暂无课程信息");
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const goToResource = (item) => {
      // console.log("item1", item);
      url.isVipResource(
        "/course/resource",
        {
          t: encode(
            `${item.resource_id},${pageData.courseId},${pageData.studentId}`
          ),
        },
        pageData.studentId,
        item?.course_resource_record?.record_resource_per,
        pageData.record_study_period_day,
        pageData.record_study_resource_day
      );
    };

    return {
      ...toRefs(pageData),
      goToIndex,
      changeTab,
      getTime,
      goToResource,
    };
  },
});
</script>
<style lang="less">
.course-content {
  img {
    max-width: 100% !important;
  }
  p span img {
    max-width: 100% !important;
  }
}
</style>
<style lang="less" scoped>
.page-main {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.course-page {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  .course-box {
    position: relative;
    .course-top {
      .course-top-1 {
        width: 100%;
        height: 200px;
        background-color: #b6b6b6;
        color: #fff;
        position: relative;
        overflow: hidden;
        .course-poster {
          filter: blur(8px) brightness(0.5);
          -webkit-filter: blur(8px) brightness(0.5);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .course-top-info {
          padding-top: 20px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
          .course-title {
            font-size: 32px;
            margin-bottom: 12px;
          }
          .course-top-row {
            padding-right: 8px;
            line-height: 12px;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 2px;
          }
          .ant-breadcrumb {
            margin-bottom: 26px;
          }
          ::v-deep .ant-breadcrumb-separator {
            color: #fff;
          }
        }
      }
      .course-tabx-box {
        background-color: #fff;
        box-shadow: 0 4px 8px 0 #1c1f211a;
        height: 68px;
        line-height: 68px;
        .course-top-row {
          align-items: center;
        }
        .course-tab-title {
          color: #1c1f21;
          position: relative;
          cursor: default;
          height: 32px;
          line-height: 32px;
          font-size: 16px;
          margin-right: 40px;
          cursor: pointer;
        }
        .course-tab-title:hover {
          color: #1890ff;
        }
        .course-tab-active::after {
          margin: 0 auto;
          content: "";
          display: block;
          width: 30px;
          right: 50%;
          transform: translateX(50%);
          border-radius: 2px;
          height: 3px;
          background: #1890ff;
          bottom: 0;
          position: absolute;
        }
      }
    }
    .course-main {
      padding-right: 352px;
      position: relative;
      .course-section-box {
        padding: 32px 0;
        .course-section-item {
          margin-bottom: 18px;
          padding: 24px 32px 32px;
          background: #fff;
          box-shadow: 0 8px 16px 0 #07111b1a;
          border-radius: 12px;
          .course-section-title {
            color: #1c1f21;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 16px;
          }
          .course-resource-box {
            .resource-title {
              // padding-left: 8px;
              padding: 12px 0 12px 8px;
              align-items: center;
              cursor: pointer;
              &-text {
                margin-right: 12px;
              }
              div {
                margin-right: 12px;
              }
              .resource-record {
                margin-right: 8px;
                margin-left: auto;
                color: #999;
              }
            }
            .resource-title:hover {
              background: #97cdff2b;
              color: #1890ff;
              border-radius: 4px;
              .resource-record {
                color: #1890ff;
              }
            }
          }
          // .course-content {
          //   img {
          //     max-width: 100% !important;
          //   }
          //   p span img {
          //     max-width: 100% !important;
          //   }
          // }
        }
      }
    }
    .course-side {
      width: 320px;
      min-height: 320px;
      padding: 24px 32px 32px;
      background-color: #fff;
      border: 1px solid #fff;
      box-shadow: 0 8px 16px 0 #07111b1a;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 18px;
      img {
        width: 120px;
        height: 120px;
        display: block;
        margin-bottom: 40px;
      }
    }
    .course-side:hover {
      box-shadow: 0 8px 16px 0 #97cdff2b;
    }
  }
}
</style>
